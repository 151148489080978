/*.row {
  margin-bottom: 10px;
}*/
.main-nav-link-wrapper {
  font-size: 0.8rem;
  display: block;
}

.main-nav-link-wrapper::after {
  content: "";
}

@media (min-width: 980px) {
  .main-nav-link-wrapper {
    display: inline-block;
    padding-right: 2px;
  }

  .main-nav-link-wrapper::after {
    content: " | ";
  }

  .main-nav-link-wrapper:last-child::after {
    content: "";
  }
}

pre {
  padding: 10px 10px;
}
.align-right {
  text-align: right;
}
.smallerfont {
  font-size: 0.8rem !important;
}
.ylbtn-link{
  font-size: 1rem;
  text-decoration: underline;
  background-color: transparent;
  border: 1px solid transparent;
}

.yltable caption {
  caption-side: top;
  text-align: left;
  font-size: .8rem;
}

.yltable th {
  font-size: 0.8rem;
  vertical-align: top;
  text-align: center;
  font-weight: normal;
}

.yltable button {
  text-decoration: underline;
  background-color: transparent;
  border: 1px solid transparent;
}

.totals-table td {
  padding: .25em .25em;
}

.ylbtn-icon {
  display: inline-block;
  vertical-align: middle;
}

.yltable-header {
  font-size: 0.8rem;
  vertical-align: middle;
  text-align: center;
}

.yltable-header-link{
  font-size: 0.8rem;
  text-decoration: underline;
  background-color: transparent;
  border: 1px solid transparent;
}

.maxwidth {
  width: 100%;
}
.ylnav {
  background-color: #fff;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.ylnavitem {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.ylspacer {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #383838;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.nav-page-title{
  display:  block;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 5px;
}
.stickyGraph{
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  border: solid 1px #666;
  height: 302px;
}
.staticGraph{
  position: static;
}
.breadcrumb{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header-link {
  font-size: .9rem;
}

.course-action-nav {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

h1 {
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.5rem;
  color: #000000;
}

.container {
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto; }
@media (min-width: 500px) {
  .container {
    max-width: 480px; } }
@media (min-width: 740px) {
  .container {
    max-width: 720px; } }
@media (min-width: 980px) {
  .container {
    max-width: 960px; } }
@media (min-width: 1220px) {
  .container {
    max-width: 1200px; } }
@media (min-width: 1620px) {
  .container {
    max-width: 1600px; } }

.container-fluid {
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;
}

.yale-topper {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.stats{
  padding-right: 30px;
  padding-left: 150px;
  margin-right: auto;
  margin-left: auto;
}

.nav-link{
  font-size: 0.875rem;
}
small{
  font-size: 80% !important;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
